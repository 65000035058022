import { SmallDashOutlined } from '@ant-design/icons';
import type { FetchMoreOptions, QueryResult } from '@apollo/client';
import config from '@zavy360/config';
import type { PageInfoFragment } from '@zavy360/graphql/operations';
import { useCallback, useIsVisible, useMemo } from '@zavy360/hooks/react';
import { useDrawerContext } from '@zavy360/ui/Drawer';
import { createDelayedDebugLogger } from '@zavy360/utils/debug';
import { Divider, Row } from 'antd';
import { nanoid } from 'nanoid';
import type React from 'react';
import { useRef } from 'react';
import { Waypoint } from 'react-waypoint';

export interface IUseInfiniteScrollOpts<Query, Variables> {
  // Whether to print debug logging for this query
  debug?: boolean;
  // Condition that if set to false, will block any refetching
  // until the condition becomes true
  name?: string;

  fetchMoreOptions?: FetchMoreOptions<Query, Variables>;
}

// biome-ignore lint/suspicious/noExplicitAny: Allowed because its only used when we cant infer the generic
const DEFAULT_OPTIONS: IUseInfiniteScrollOpts<any, unknown> = {
  debug: false || config.apollo.hooks.lazyQueryEffect.debug
};

const log = createDelayedDebugLogger();

export interface IFetchMoreWaypointProps extends Partial<Omit<PropsOf<typeof Waypoint>, 'children'>> {
  children?: React.ReactNode;
  onAfterFetch?(): void;
  onBeforeFetch?(): void;
}

export interface IUseInfiniteScrollResult {
  FetchMoreWaypoint: React.FC<IFetchMoreWaypointProps>;
  fetchNextPage(): void;
  fetchAllPages(after?: string): Promise<void>;
}

export const EMPTY_INFINITE_SCROLL_RESULT: IUseInfiniteScrollResult = {
  FetchMoreWaypoint: ({ children }: IFetchMoreWaypointProps) => {
    if (children) return children as JSX.Element;

    return (
      <Row justify='center'>
        <Divider>
          <SmallDashOutlined />
        </Divider>
      </Row>
    );
  },
  fetchAllPages: async () => null,
  fetchNextPage: () => null
};

function WaypointComponent(props: IFetchMoreWaypointProps & { onEnter: PropsOf<typeof Waypoint>['onEnter'] }) {
  const { onAfterFetch, onBeforeFetch, children, ...rest } = props;

  // If we're inside a drawer, but the drawer isn't open,
  // dont render the waypoint. This ONLY works with @zavy360/ui/Drawer
  // because antd doesn't expose `open` in any context.
  // When rendering FetchMoreWaypoint inside an antd modal or drawer,
  // and not using @zavy360/ui/Drawer, you will need to handle this manually
  const { contextInitialized, open } = useDrawerContext();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);

  if (contextInitialized && !open) return null;
  return (
    <div ref={ref}>
      {isVisible && (
        <Waypoint key={nanoid()} bottomOffset={-300} {...rest}>
          {children ? children : <div />}
        </Waypoint>
      )}
    </div>
  );
}

/**
 * In a lot of places we have a useEffect that checks
 * whether variables have changes for a lazy query,
 * and then refetches it. This is done *slightly* different
 * in a lot of places, and we've had issues where this is done
 * in a way that doesn't actually fetch the query correctly.
 *
 * This hook provides memoization of variables *and* an effect to
 * refetch when the variables change, and can be used like this:
 *
 * const [getSomething, query] = useSomethingLazyQuery();
 * useLazyQueryEffect(getSomething, query.variables, { id: something.id })
 *
 * If you need to do something else on top of this, provide the onRefetch
 * option. Debugging can be enabled on a global level in 'config/AppConfig'
 * by setting `apollo.hooks.lazyQueryEffect.debug` to true,
 * or by passing { debug: true } in the options
 */
export function useInfiniteScroll<Variables extends { after: string }, Query extends object>(
  query: Pick<QueryResult<Query, Variables>, 'loading' | 'fetchMore' | 'data'>,
  getPageInfo: (data: Query) => Pick<Partial<PageInfoFragment>, 'endCursor' | 'hasNextPage'>,
  opts: IUseInfiniteScrollOpts<Query, Variables> = DEFAULT_OPTIONS
): IUseInfiniteScrollResult {
  // Set up the default options
  const { debug, name, fetchMoreOptions } = useMemo(() => ({ ...DEFAULT_OPTIONS, ...opts }), [opts]);

  const { loading, fetchMore, data } = query;
  const pageInfo = getPageInfo?.(data) || { endCursor: null, hasNextPage: false };

  const { endCursor, hasNextPage } = pageInfo || { endCursor: null, hasNextPage: false };

  const fetchNextPage = useCallback(() => {
    if (loading) {
      if (debug)
        log(
          `[Apollo::Hooks::useLazyQueryEffect::${name}::useInfiniteScroll] ${
            name || ''
          } - endCursor: ${endCursor}, hasNextPage: ${hasNextPage}`
        );
      return;
    }
    if (debug) {
      log(
        `[Apollo::Hooks::useLazyQueryEffect::${name}::useInfiniteScroll] ${
          name || ''
        } - endCursor: ${endCursor}, hasNextPage: ${hasNextPage}`
      );
    }

    if (!endCursor) return;

    return fetchMore({
      variables: {
        after: endCursor
      },
      ...fetchMoreOptions
    });
  }, [loading, debug, fetchMoreOptions, fetchMore, endCursor, name, hasNextPage]);

  const fetchAllPages = useCallback(
    async function FetchAllPages(after?: string) {
      const response = await fetchMore({
        variables: {
          after: after || endCursor
        },
        ...fetchMoreOptions
      });

      const newPageInfo = getPageInfo(response.data);
      if (newPageInfo.hasNextPage) {
        return fetchAllPages(newPageInfo.endCursor);
      }
      return;
    },
    [endCursor, fetchMore, fetchMoreOptions, getPageInfo]
  );

  const onWaypointEnter = useCallback(
    (args: Waypoint.CallbackArgs) => {
      if (debug)
        log(
          `[Apollo::Hooks::useLazyQueryEffect::${name}::useInfiniteScroll] ${
            name || ''
          }: Waypoint entered viewport, fetching next page`
        );
      return fetchNextPage();
    },
    [debug, fetchNextPage, name]
  );

  const FetchMoreWaypoint: React.FC<IFetchMoreWaypointProps> = useMemo(() => {
    return (props: IFetchMoreWaypointProps) => {
      const { onAfterFetch, onBeforeFetch, children, ...rest } = props;
      return (
        <WaypointComponent
          onEnter={(...args) => {
            onBeforeFetch?.();
            if (onWaypointEnter(...args)) onAfterFetch?.();
          }}
        />
      );
    };
  }, [onWaypointEnter]);

  if (!pageInfo?.hasNextPage && !loading) return EMPTY_INFINITE_SCROLL_RESULT;

  return { fetchNextPage, FetchMoreWaypoint, fetchAllPages };
}
